.img {
  /* width: "80%"; */
  /* height: 100vh; */
  /* height: auto; */
  /* width: 80vh; */
  cursor: pointer;
  /* max-height: calc(100vh - 56px - 159px - 68px); */
}

.cardHover:hover {
  background-color: aqua;
  color: white;
}
